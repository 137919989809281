import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faHome, faUser } from '@fortawesome/free-solid-svg-icons';
import '../styles/FooterNav.css';

function FooterNav() {
  return (
    <footer className="footer-nav">
      <div className="nav-item">
        <FontAwesomeIcon icon={faCommentDots} size="2x" />
      </div>
      <div className="nav-item">
        <Link to="/"> {/* Wrap the home icon in a Link to navigate to the home page */}
          <FontAwesomeIcon icon={faHome} size="2x" />
        </Link>
      </div>
      <div className="nav-item">
        <Link to="/user"> {/* Add the link to the user page */}
          <FontAwesomeIcon icon={faUser} size="2x" />
        </Link>
      </div>
    </footer>
  );
}

export default FooterNav;