// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import '../styles/AuthForm.css';

const Login = () => {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const { data, error } = await signIn({ email, password });
      if (error) throw error;

      // Store the userId in localStorage
      localStorage.setItem('userId', data.user.id);
      // If you need to store the email as well (equivalent to the old username)
      localStorage.setItem('username', email);

      navigate('/');
    } catch (error) {
      setError(error.message);
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="auth-form-container">
      <h2 className="auth-form-title">Login</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleLogin}>
        <div className="auth-form-group">
          <label htmlFor="email" className="auth-form-label">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="auth-form-input"
            required
          />
        </div>
        <div className="auth-form-group">
          <label htmlFor="password" className="auth-form-label">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="auth-form-input"
            required
          />
        </div>
        <button type="submit" className="auth-form-button">Login</button>
      </form>
      <div className="auth-form-footer">
        <p>Don't have an account? <Link to="/register">Register here</Link></p>
      </div>
    </div>
  );
};

export default Login;