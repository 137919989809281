import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/UserPage.css';

const UserPage = () => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    // Clear the stored user data
    localStorage.removeItem('userId');
    localStorage.removeItem('username');

    // Navigate back to the login page
    navigate('/login');
  };

  return (
    <div className="user-page-container">
      <h2 className="user-page-title">User Profile</h2>
      <button className="sign-out-button" onClick={handleSignOut}>
        Sign Out
      </button>
    </div>
  );
};

export default UserPage;