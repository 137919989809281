import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient'; // Import the Supabase client
import '../styles/MyClimbingCard.css';

function MyClimbingCard() {
  const [data, setData] = useState({
    sessions: 0,
    ticks: 0,
    bouldering: { best: null, avg: null },
    topRope: { best: null, avg: null },
    lead: { best: null, avg: null },
  });

  const userId = localStorage.getItem('userId'); // Retrieve the logged-in user's userId from localStorage

  useEffect(() => {
    const fetchData = async () => {
      if (!userId) {
        console.error('No user ID found in localStorage.');
        return;
      }

      try {
        // Fetch the user's climbing sessions from Supabase
        const { data: sessions, error } = await supabase
          .from('climbing_sessions')
          .select('*')
          .eq('user_id', userId);

        if (error) {
          console.error('Error fetching climbing data from Supabase:', error);
          return;
        }

        // Aggregate the data (this is an example, adjust it to your needs)
        const ticks = sessions.length;
        const boulderingStats = calculateStats(sessions, 'Boulder');
        const topRopeStats = calculateStats(sessions, 'TopRope');
        const leadStats = calculateStats(sessions, 'Lead');

        setData({
          sessions: ticks,
          ticks,
          bouldering: boulderingStats,
          topRope: topRopeStats,
          lead: leadStats,
        });
      } catch (error) {
        console.error('Error fetching climbing data:', error);
      }
    };

    fetchData();
  }, [userId]);

  const calculateStats = (sessions, type) => {
    const filteredRoutes = sessions
      .flatMap(session => session.routes)
      .filter(route => route.type === type);

    if (filteredRoutes.length === 0) return { best: null, avg: null };

    const grades = filteredRoutes.map(route => convertGradeToNumeric(route.grade));
    const best = Math.max(...grades);
    const avg = (grades.reduce((sum, grade) => sum + grade, 0) / grades.length).toFixed(1);

    return {
      best: convertNumericToGrade(best, type),
      avg: convertNumericToGrade(avg, type),
    };
  };

  const convertGradeToNumeric = (grade) => {
    // Convert grade strings to numeric values for comparison (adjust to your grading system)
    const gradeMap = {
      'V1': 1, 'V2': 2, 'V3': 3, 'V4': 4, 'V5': 5, 'V6': 6, 'V7': 7, 'V8': 8, 'V9': 9,
      '5.6': 6, '5.7': 7, '5.8': 8, '5.9': 9, '5.10': 10, '5.11': 11, '5.12': 12, '5.13': 13, '5.14': 14
    };
    return gradeMap[grade] || 0;
  };

  const convertNumericToGrade = (number, type) => {
    // Convert numeric values back to grade strings
    const boulderGrades = ['V1', 'V2', 'V3', 'V4', 'V5', 'V6', 'V7', 'V8', 'V9'];
    const ropeGrades = ['5.6', '5.7', '5.8', '5.9', '5.10', '5.11', '5.12', '5.13', '5.14'];
    const gradeList = type === 'Boulder' ? boulderGrades : ropeGrades;
    return gradeList[number - 1] || 'N/A';
  };

  return (
    <div className="climbing-card">
      <div className="climbing-header">
        <h2 className="climbing-title">
          <span>My</span> <span>Climbing</span>
        </h2>
        <div className="climbing-stats">
          <div className="sessions">
            <p>{data.sessions}</p>
            <span>sessions</span>
          </div>
          <div className="ticks">
            <p>{data.ticks}</p>
            <span>ticks</span>
          </div>
        </div>
      </div>

      <div className="climbing-categories">
        {/* Boulder stats */}
        {data.bouldering && (
          <div className="category">
            <h3>Bouldering</h3>
            <div className="values">
              <div className="best">
                <p>best</p>
                <span>{data.bouldering.best || 'N/A'}</span>
              </div>
              <div className="divider"></div>
              <div className="avg">
                <p>avg</p>
                <span>{data.bouldering.avg || 'N/A'}</span>
              </div>
            </div>
          </div>
        )}

        {/* Top-Rope stats */}
        {data.topRope && (
          <div className="category">
            <h3>Top-Rope</h3>
            <div className="values">
              <div className="best">
                <p>best</p>
                <span>{data.topRope.best || 'N/A'}</span>
              </div>
              <div className="divider"></div>
              <div className="avg">
                <p>avg</p>
                <span>{data.topRope.avg || 'N/A'}</span>
              </div>
            </div>
          </div>
        )}

        {/* Lead stats */}
        {data.lead && (
          <div className="category">
            <h3>Lead</h3>
            <div className="values">
              <div className="best">
                <p>best</p>
                <span>{data.lead.best || 'N/A'}</span>
              </div>
              <div className="divider"></div>
              <div className="avg">
                <p>avg</p>
                <span>{data.lead.avg || 'N/A'}</span>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* New Session Button */}
      <div className="new-session-btn-container">
        <Link to="/log-session" className="new-session-btn">
          New Session
        </Link>
      </div>
    </div>
  );
}

export default MyClimbingCard;