import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import Header from './components/Header';
import FooterNav from './components/FooterNav';
import MyClimbingCard from './components/MyClimbingCard';
import SessionNotes from './components/SessionNotesForm';
import LogSessionForm from './components/LogSessionForm';
import SessionDetailsForm from './components/SessionDetailsForm';
import Login from './components/Login';
import Register from './components/Register';
import PrivateRoute from './components/PrivateRoute';
import UserPage from './components/UserPage';
import InstallPrompt from './components/InstallPrompt';
import './styles/global.css';

function App() {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallPrompt(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the install prompt');
      } else {
        console.log('User dismissed the install prompt');
      }
      setDeferredPrompt(null);
      setShowInstallPrompt(false);
    }
  };

  return (
    <AuthProvider>
      <Router>
        <div className="app-container">
          <Header />
          <main className="main-content">
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />

              {/* Private Routes */}
              <Route path="/" element={<PrivateRoute><MyClimbingCard /></PrivateRoute>} />
              <Route path="/log-session" element={<PrivateRoute><LogSessionForm /></PrivateRoute>} />
              <Route path="/session-details" element={<PrivateRoute><SessionDetailsForm /></PrivateRoute>} />
              <Route path="/session-notes" element={<PrivateRoute><SessionNotes /></PrivateRoute>} />
              <Route path="/user" element={<PrivateRoute><UserPage /></PrivateRoute>} />
            </Routes>
          </main>
          {showInstallPrompt && <InstallPrompt onClick={handleInstallClick} />}
          <FooterNav />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;