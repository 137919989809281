import React from 'react';
import '../styles/LogSessionForm.css';

const SessionNotesForm = ({ session, handleSessionChange, handleSubmitSession }) => (
  <form className="log-session-form" onSubmit={handleSubmitSession}>
    <div className="session-details">
      <div className="gym-name">{session.gymName}</div>
      <div className="session-date">{session.date}</div>
    </div>

    <h3 className="form-subtitle">Session Notes</h3>
    <div className="form-group">
      <textarea
        name="sessionNotes"
        value={session.sessionNotes}
        onChange={handleSessionChange}
        className="input-field"
      />
    </div>

    <div className="button-container">
      <button type="submit" className="btn-submit">
        Log Session
      </button>
    </div>
  </form>
);

export default SessionNotesForm;