import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import '../styles/AuthForm.css';

// Initialize Supabase client
const supabase = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');  // Updated to use email instead of username
  const [password, setPassword] = useState('');
  const [inviteCode, setInviteCode] = useState('');

  const handleRegister = async (e) => {
    e.preventDefault();

    if (inviteCode !== 'justsend') {
      alert('Invalid invite code');
      return;
    }

    try {
      const { error, data } = await supabase.auth.signUp({
        email,
        password,
      });
      if (error) throw error;

      // Store userId in localStorage
      localStorage.setItem('userId', data.user.id);

      // Redirect to the homepage after successful registration
      navigate('/');
    } catch (error) {
      console.error('Registration failed:', error);
    }
  };

  return (
    <div className="auth-form-container">
      <h2 className="auth-form-title">Register</h2>
      <form onSubmit={handleRegister}>
        <div className="auth-form-group">
          <label className="auth-form-label">Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="auth-form-input"
            required
          />
        </div>
        <div className="auth-form-group">
          <label className="auth-form-label">Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="auth-form-input"
            required
          />
        </div>
        <div className="auth-form-group">
          <label className="auth-form-label">Invite Code:</label>
          <input
            type="text"
            value={inviteCode}
            onChange={(e) => setInviteCode(e.target.value)}
            className="auth-form-input"
            required
          />
        </div>
        <button type="submit" className="auth-form-button">Register</button>
      </form>

      <div className="auth-form-footer">
        <p>
          Already have an account? <Link to="/login">Login here</Link>
        </p>
      </div>
    </div>
  );
};

export default Register;