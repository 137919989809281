import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient'; // Import the Supabase client
import StarRating from './StarRating';
import RouteItem from './RouteItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFeather, faMountain, faPlus, faMinus, faCheck, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import '../styles/LogSessionForm.css';

function LogSessionForm() {
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const gradeRanges = {
    Boulder: ['V1', 'V2', 'V3', 'V4', 'V5', 'V6', 'V7', 'V8', 'V9', 'V10', 'V11', 'V12', 'V13', 'V14'],
    TopRope: ['5.6', '5.7', '5.8', '5.9', '5.10', '5.11', '5.12', '5.13', '5.14'],
    Lead: ['5.6', '5.7', '5.8', '5.9', '5.10', '5.11', '5.12', '5.13', '5.14'],
  };

  const [session, setSession] = useState({
    user_id: userId || '',
    gym_name: 'Gecko', // Adjust to match Supabase table
    session_date: getTodayDate(), // Adjust to match Supabase table
    session_notes: '',
    routes: [],
  });

  const [showRoutesForm, setShowRoutesForm] = useState(false);
  const [showNotesInput, setShowNotesInput] = useState(false);

  const [currentRoute, setCurrentRoute] = useState({
    type: 'Boulder',
    grade: 'V7',
    gradeFeedback: 'accurate',
    rating: 0,
    completionStatus: 'redpoint',
    routeNotes: '',
  });

  const incrementGrade = () => {
    const gradeList = gradeRanges[currentRoute.type];
    const currentIndex = gradeList.indexOf(currentRoute.grade);
    if (currentIndex < gradeList.length - 1) {
      setCurrentRoute({ ...currentRoute, grade: gradeList[currentIndex + 1] });
    }
  };

  const decrementGrade = () => {
    const gradeList = gradeRanges[currentRoute.type];
    const currentIndex = gradeList.indexOf(currentRoute.grade);
    if (currentIndex > 0) {
      setCurrentRoute({ ...currentRoute, grade: gradeList[currentIndex - 1] });
    }
  };

  const handleSessionChange = (e) => {
    setSession({ ...session, [e.target.name]: e.target.value });
  };

  const handleRouteChange = (e) => {
    setCurrentRoute({ ...currentRoute, [e.target.name]: e.target.value });
  };

  const addRouteToSession = () => {
    if (session.gym_name && session.session_date) {
      setSession({
        ...session,
        routes: [...session.routes, currentRoute],
      });
      setCurrentRoute({
        type: 'Boulder',
        grade: 'V7',
        gradeFeedback: 'accurate',
        rating: 0,
        completionStatus: 'redpoint',
        routeNotes: '',
      });
      setShowNotesInput(false);
    } else {
      alert('Please make sure the Gym Name and Date are set before adding routes.');
    }
  };

  const handleStarClick = (rating) => {
    setCurrentRoute({ ...currentRoute, rating });
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    if (session.gym_name && session.session_date) {
      setShowRoutesForm(true); 
    } else {
      alert('Please enter Gym Name and Date.');
    }
  };

  // Handle the form submission and save session data to Supabase
  const handleSubmitSession = async (e) => {
    e.preventDefault();
    try {
      const { data, error } = await supabase
        .from('climbing_sessions')
        .insert([
          {
            user_id: session.user_id,
            gym_name: session.gym_name,
            session_date: session.session_date,
            session_notes: session.session_notes,
            routes: session.routes, // Save routes as JSON
          }
        ]);
  
      if (error) {
        throw error;
      }
  
      console.log('Session saved:', data);
      navigate('/');
    } catch (error) {
      console.error('Error saving session:', error);
    }
  };

  const toggleNotesInput = () => {
    setShowNotesInput(!showNotesInput);
  };

  return (
    <div>
      {!showRoutesForm ? (
        <form onSubmit={handleNextStep}>
          <h2 className="form-title">Log Climbing Session</h2>
          <div className="form-group">
            <label>Gym Name:</label>
            <input
              type="text"
              name="gym_name"
              value={session.gym_name}
              onChange={handleSessionChange}
              className="input-field"
              required
            />
          </div>
          <div className="form-group">
            <label>Date:</label>
            <input
              type="date"
              name="session_date"
              value={session.session_date}
              onChange={handleSessionChange}
              className="input-field"
              required
            />
          </div>
          <div className="button-container">
            <button type="submit" className="btn-submit">
              Next: Add Routes
            </button>
          </div>
        </form>
      ) : (
        <form onSubmit={handleSubmitSession}>

          <div className="form-group">
            <div className="log-route-container">
              <div className="route-type-toggle">
                <button
                  type="button"
                  className={`toggle-button ${currentRoute.type === 'Boulder' ? 'active' : ''}`}
                  onClick={() => setCurrentRoute({ ...currentRoute, type: 'Boulder', grade: 'V7' })}
                >
                  Boulder
                </button>
                <button
                  type="button"
                  className={`toggle-button ${currentRoute.type === 'TopRope' ? 'active' : ''}`}
                  onClick={() => setCurrentRoute({ ...currentRoute, type: 'TopRope', grade: '5.10' })}
                >
                  Top Rope
                </button>
                <button
                  type="button"
                  className={`toggle-button ${currentRoute.type === 'Lead' ? 'active' : ''}`}
                  onClick={() => setCurrentRoute({ ...currentRoute, type: 'Lead', grade: '5.10' })}
                >
                  Lead
                </button>
              </div>

              <div className="grade-selector">
                <FontAwesomeIcon
                  icon={faMinus}
                  className="grade-icon"
                  onClick={decrementGrade}
                />
                <span className="grade-value">{currentRoute.grade}</span>
                <FontAwesomeIcon
                  icon={faPlus}
                  className="grade-icon"
                  onClick={incrementGrade}
                />
              </div>

              <div className="rating-feedback-row">
                <div className="star-container">
                  <StarRating rating={currentRoute.rating} handleStarClick={handleStarClick} />
                </div>
                <div className="feedback-container">
                  <FontAwesomeIcon
                    icon={faFeather}
                    className={currentRoute.gradeFeedback === 'soft' ? 'feedback-icon selected' : 'feedback-icon'}
                    onClick={() => handleRouteChange({ target: { name: 'gradeFeedback', value: 'soft' } })}
                    title="Soft"
                  />
                  <div className="divider"></div>
                  <FontAwesomeIcon
                    icon={faMountain}
                    className={currentRoute.gradeFeedback === 'hard' ? 'feedback-icon selected' : 'feedback-icon'}
                    onClick={() => handleRouteChange({ target: { name: 'gradeFeedback', value: 'hard' } })}
                    title="Hard"
                  />
                </div>
              </div>

              <div className="completion-status-toggle">
                <button
                  type="button"
                  className={`toggle-button ${currentRoute.completionStatus === 'redpoint' ? 'active' : ''}`}
                  onClick={() => setCurrentRoute({ ...currentRoute, completionStatus: 'redpoint' })}
                >
                  Redpoint
                </button>
                <button
                  type="button"
                  className={`toggle-button ${currentRoute.completionStatus === 'Flash' ? 'active' : ''}`}
                  onClick={() => setCurrentRoute({ ...currentRoute, completionStatus: 'Flash' })}
                >
                  Flash
                </button>
                <button
                  type="button"
                  className={`toggle-button project-toggle ${currentRoute.completionStatus === 'Project' ? 'active project' : ''}`}
                  onClick={() => setCurrentRoute({ ...currentRoute, completionStatus: 'Project' })}
                >
                  Project
                </button>
              </div>

              {showNotesInput ? (
                <textarea
                  name="routeNotes"
                  value={currentRoute.routeNotes}
                  onChange={handleRouteChange}
                  className="input-field"
                  placeholder="Enter route notes"
                />
              ) : null}

              <div className="add-route-container">
                {!showNotesInput && (
                  <button
                    type="button"
                    className="btn-add-notes"
                    onClick={toggleNotesInput}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} /> Notes
                  </button>
                )}

                <button
                  type="button"
                  className="btn-add-route"
                  onClick={addRouteToSession}
                >
                  <FontAwesomeIcon icon={faCheck} /> Add
                </button>
              </div>
            </div>
          </div>

          <ul className="routes-list">
            {session.routes && session.routes.length > 0 ? (
              session.routes.map((route, index) => (
                <RouteItem key={index} route={route} />
              ))
            ) : (
              <li>No routes added yet.</li>
            )}
          </ul>

          <div className="button-container">
            <button type="submit" className="btn-submit">
              Log Session
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default LogSessionForm;