import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import '../styles/LogSessionForm.css';

const StarRating = ({ rating, handleStarClick }) => (
  <div className="star-rating">
    {[1, 2, 3].map((star) => (
      <FontAwesomeIcon
        key={star}
        icon={faStar}
        className={star <= rating ? 'star selected' : 'star'}
        onClick={() => handleStarClick(star)}
      />
    ))}
  </div>
);

export default StarRating;