import React from 'react';
import '../styles/LogSessionForm.css';

const SessionDetailsForm = ({ session, handleSessionChange, handleNextStep }) => {
  return (
    <form className="log-session-form" onSubmit={handleNextStep}>
      <h2 className="form-title">Log Climbing Session</h2>
      <div className="form-group">
        <label>Gym Name:</label>
        <input
          type="text"
          name="gymName"
          value={session.gymName}
          onChange={handleSessionChange}
          className="input-field"
          required
        />
      </div>
      <div className="form-group">
        <label>Date:</label>
        <input
          type="date"
          name="date"
          value={session.date}
          onChange={handleSessionChange}
          className="input-field"
          required
        />
      </div>
      <div className="button-container">
        <button type="submit" className="btn-submit">
          Next: Add Routes
        </button>
      </div>
    </form>
  );
};

export default SessionDetailsForm;