import React from 'react';
import '../styles/LogSessionForm.css'; // Assuming there's a separate CSS file for RouteItem

const RouteItem = ({ route }) => {
  return (
    <li className="route-item">
      <div className="route-type">{route.type}</div>
      <div className="route-grade">{route.grade}</div>
      <div className="route-feedback">{route.gradeFeedback}</div>
      <div className="route-rating">
        {Array(route.rating).fill('⭐').join('')} {/* Display stars based on rating */}
      </div>
      <div className="route-attempts">Attempts: {route.attempts}</div>
      {route.routeNotes && <div className="route-notes">{route.routeNotes}</div>}
    </li>
  );
};

export default RouteItem;