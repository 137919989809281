// src/components/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { supabase } from '../supabaseClient';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setUser(session?.user ?? null);
      setLoading(false);
    };

    fetchSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUser(session?.user ?? null);
      setLoading(false);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const signUp = async (data) => {
    const { data: authData, error } = await supabase.auth.signUp(data);
    if (!error && authData.user) {
      setUser(authData.user);
      localStorage.setItem('userId', authData.user.id);
    }
    return { data: authData, error };
  };

  const signIn = async (data) => {
    const { data: authData, error } = await supabase.auth.signInWithPassword(data);
    if (!error && authData.user) {
      setUser(authData.user);
      localStorage.setItem('userId', authData.user.id);
    }
    return { data: authData, error };
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      setUser(null);
      localStorage.removeItem('userId');
    }
    return { error };
  };

  const value = {
    signUp,
    signIn,
    signOut,
    user,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};